import { createRouter, createWebHistory } from "vue-router";
import { memberRoutes } from "@/Member/router/member.routes";
import { adminRoutes } from "@/Admin/router/admin.routes";
import { loginRoutes } from "@/Login/router/login.routes";

const routes = [...memberRoutes, ...adminRoutes, ...loginRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  const isAuthenticated = localStorage.getItem("authToken"); // Ejemplo con token

  if (!isAuthenticated && to.path !== "/login") {
    next("/login"); // Redirige al login si no está autenticado
  } else {
    next(); // Permite la navegación
  }
});

export default router;
